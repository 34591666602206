import React from "react"

import Layout from "../../components/en/Layout"
import SEO from "../../components/en/SEO"
import Breadcrumbs from "../../components/en/Breadcrumbs"

import "./Pages.css"

const PoliticaDePrivacidade = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Privacy Policy"
        canonical={`${process.env.GATSBY_HOST_URL}/en/privacy-policy`}
        isAlternate={true}
        alternateUrl={`${process.env.GATSBY_HOST_URL}/pt/politica-de-privacidade`}
      />

      <div className="topics">
        <div className="main-content" style={{ marginTop: 117 }}>
          <Breadcrumbs
            currentPage="Privacy Policy"
            style={{ marginTop: 0, marginBottom: 30 }}
          />

          <div className="topics-header">
            <h1>Privacy Policy</h1>
          </div>

          <div className="content-static-pages">
            <strong>A. The introduction</strong>
            <p>
              The privacy of visitors to our website is very important to us,
              and we are committed to protecting it. This policy explains what
              we will do with your personal information. For this reason, by
              consenting to the use of cookies in accordance with the terms of
              this policy when accessing our website (Nizam app) for the first
              time, it allows us to use cookies every time you access our
              website.
            </p>

            <strong>B. Credits</strong>
            <p>
              This document was created using a SEQ Legal template
              (https://seqlegal.com/) modified by the legal team of Taha Brasil
              Tecnologia e Marketing LTDA, registered with CNPJ nº 35.950.269 /
              0001-13, with address Rua Marechal Floriano nº 654, Sala 607,
              Centro, CEP 35010-140, Governador Valadares - MG.
            </p>

            <strong>C. Collection of personal information</strong>
            <p>
              The following types of personal information can be collected,
              stored and used:
            </p>

            <ul>
              <li>
                information about your computer, including your IP address,
                geographic location, browser type and version and operating
                system;
              </li>
              <li>
                information about your visits and use of this website, including
                reference source, visit duration, page views and navigation
                paths on the website;
              </li>
              <li>
                information, such as your email address, that you enter when
                registering on our site;
              </li>
              <li>
                information you enter when creating a profile on our website -
                for example, your name, profile photos, gender, date of birth,
                relationship status, interests and hobbies, educational and
                employment information;
              </li>
              <li>
                information, such as your name and email address, that you enter
                to set up subscriptions to our emails and / or newsletters;
              </li>
              <li>
                information you enter when using the services on our website;
              </li>
              <li>
                information generated when using our website, including when,
                how often and under what circumstances you use it;
              </li>
              <li>
                information related to everything you buy, services you use or
                transactions you make through our website, including name,
                address, telephone number, email address and credit card
                details;
              </li>
              <li>
                information that you post on our website with the intention of
                posting it on the internet, including your username, profile
                pictures and the content of your publications;
              </li>
              <li>
                information contained in any communications you send to us by
                email or through our website, including the content and metadata
                of the communication;
              </li>
              <li>any other personal information you send us.</li>
            </ul>

            <p>
              Important: Before disclosing another person's personal information
              to us, remember that you must obtain that person's consent for the
              disclosure and processing of that personal information in
              accordance with this policy, under penalty of possible civil and
              criminal liability.
            </p>

            <strong>D. Use of your personal information</strong>

            <p>
              The personal information sent to us through our website will be
              used for the purposes specified in this policy or on the relevant
              pages of the website. We may use your personal information for the
              following:
            </p>

            <ul>
              <li>administer our website and our business;</li>
              <li>customize our website for you;</li>
              <li>enable the use of the services available on our website;</li>
              <li>send products purchased through our website;</li>
              <li>provide services acquired through our website;</li>
              <li>
                send statements, invoices and payment reminders, as well as
                collect your payments;
              </li>
              <li>send non-marketing commercial communications;</li>
              <li>send email notifications specifically requested by you;</li>
              <li>
                send our newsletter by email, if you have requested it (you can
                inform us at any time if you no longer wish to receive the
                newsletter);
              </li>
              <li>
                send marketing communications related to our business or the
                carefully selected third-party business that we believe will be
                of interest to you, by mail or, where you specifically agreed to
                do so, by email or similar technology (you can inform us at any
                time if not most want more to receive marketing communications);
              </li>
              <li>
                provide third parties with statistical information about our
                users (but these third parties will not be able to identify any
                individual user from that information);
              </li>
              <li>
                deal with questions and complaints made by or about you
                regarding our website;
              </li>
              <li>keep our website safe and prevent fraud;</li>
              <li>
                verify compliance with the terms and conditions governing the
                use of our website (including monitoring private messages sent
                through our website's private messaging service); and
              </li>
              <li>other uses.</li>
            </ul>

            <p>
              If you submit personal information for publication on our website,
              we will publish and use that information in accordance with the
              license you have granted us.
            </p>

            <p>
              Your privacy settings can be used to limit the publication of your
              information on our website and adjusted through the use of privacy
              controls on the website.
            </p>

            <p>
              Without your express consent, we will not provide your personal
              information to third parties for the purposes of direct marketing
              by them or third parties.
            </p>

            <strong>E. Disclosure of personal information</strong>

            <p>
              We may disclose your personal information to any of our employees,
              executives, insurance companies, professional consultants ,
              agents, suppliers or subcontractors as reasonably necessary for
              the purposes set out in this policy.
            </p>

            <p>
              We may disclose your personal information to any member of our
              group of companies (this means our subsidiaries, our holding
              company and all of its subsidiaries) as reasonably necessary for
              the purposes set out in this policy.
            </p>

            <p>We may disclose your personal information:</p>

            <ul>
              <li>insofar as we are required to do so by law;</li>
              <li>in relation to any ongoing or potential lawsuit;</li>
              <li>
                to establish, exercise or defend our legal rights (including
                providing information to third parties for the purposes of fraud
                prevention and credit risk reduction);
              </li>
              <li>
                to the buyer (or potential buyer) of any business or asset that
                we are selling (or contemplating selling); and
              </li>
              <li>
                to any person we reasonably believe can request a court or other
                competent authority to disclose that personal information, when,
                in our reasonable opinion, it is likely that such court or
                authority will order the disclosure of that personal
                information.
              </li>
              <li>
                Except as provided in this policy, we will not provide your
                personal information to third parties.
              </li>
            </ul>

            <strong style={{ display: "block", marginTop: "1em" }}>
              F. International data transfers
            </strong>

            <p>
              The information we collect may be stored, processed and
              transferred between any of the countries in which we operate in
              order to allow us to use the information in accordance with this
              policy.
            </p>

            <p>
              Personal information that you post on our site or submit for
              publication on our site may be available, via the internet,
              worldwide. We cannot prevent the use or misuse of such information
              by third parties.
            </p>

            <p>
              Therefore, you expressly agree to the transfers of personal
              information described in this section F.
            </p>

            <strong>G. Retention of personal information</strong>

            <p>
              This section G defines our data retention policies and procedures,
              designed to help ensure that we comply with our legal obligations
              in relation to the retention and deletion of personal information.
            </p>

            <p>
              The personal information we process for any purpose or purposes
              should not be kept any longer than necessary for that purpose or
              purposes.
            </p>

            <p>
              Notwithstanding the other provisions of this section G, we will
              retain documents (including electronic documents) that contain
              personal data:
            </p>

            <ul>
              <li>insofar as we are required to do so by law;</li>
              <li>
                if we believe that the documents may be relevant to any ongoing
                or potential litigation; and
              </li>
              <li>
                to establish, exercise or defend our legal rights (including
                providing information to third parties for the purposes of
                preventing fraud and reducing credit risk).
              </li>
            </ul>

            <strong style={{ display: "block", marginTop: "1em" }}>
              H. Security of your personal information
            </strong>

            <p>
              We will take appropriate technical and organizational precautions
              to prevent the loss, misuse or alteration of your personal
              information.
            </p>

            <p>
              We will store all of your personal information provided on our
              secure servers (password and firewall protected).
            </p>

            <p>
              All electronic financial transactions carried out through our
              website will be protected by encryption technology.
            </p>

            <p>
              You acknowledge that the transmission of information over the
              internet is inherently insecure and that we cannot guarantee the
              security of data sent over the internet.
            </p>

            <p>
              You are responsible for keeping the password used to access our
              website confidential; we will not ask for your password (except
              when you log in to our site).
            </p>

            <strong>I. Changes</strong>

            <p>
              We may update this policy periodically by posting a new version on
              our website. You should check this page occasionally to ensure
              that you understand any changes to this policy. We may notify you
              of changes to this policy by email or via the private messaging
              system on our website.
            </p>

            <strong>J. Your rights</strong>

            <p>
              You can instruct us to provide any personal information we hold
              about you; the provision of such information will be subject to
              the following:
            </p>

            <ul>
              <li>payment of a fee of USD 25.00 per hour of work; and</li>
              <li>
                providing appropriate evidence of your identity (for this
                purpose; we will generally accept a certified copy of your
                document, plus an original copy of a service invoice showing
                your current address).
              </li>
              <li>
                We may retain the requested personal information to the extent
                permitted by law.
              </li>
              <li>
                You can instruct us at any time not to process your personal
                information for marketing purposes.
              </li>
              <li>
                In practice, you will generally expressly agree in advance with
                the use of your personal information for marketing purposes, or
                we will offer the opportunity to not allow the use of your
                personal information for marketing purposes.
              </li>
            </ul>

            <strong style={{ display: "block", marginTop: "1em" }}>
              K. Third party sites
            </strong>

            <p>
              Our website includes links to and details of third party websites.
              We have no control over and are not responsible for the privacy
              policies and practices of third parties.
            </p>

            <strong>L. Updating information</strong>

            <p>
              Please let us know if the personal information we hold about you
              needs to be corrected or updated.
            </p>

            <strong style={{ display: "block" }}>M. Cookies</strong>

            <p>
              Our website uses cookies. A cookie is a file that contains an
              identifier (a sequence of letters and numbers) that is sent by a
              web server to a browser and stored by the browser. The identifier
              is then sent back to the server each time the browser requests a
              page from the server. Cookies can be "persistent" cookies or
              "session" cookies: a persistent cookie will be stored by a browser
              and will remain valid until the defined expiration date, unless it
              is deleted by the user before the expiration date; a session
              cookie, on the other hand, will expire at the end of the user's
              session, when the browser is closed. Cookies do not normally
              contain any information that personally identifies a user, but the
              personal information we store about you may be linked to
              information stored and obtained from cookies. We use persistent
              and session cookies on our website.
            </p>

            <p>
              The names of the cookies we use on our website and the purposes
              for which they are used are described below:
            </p>

            <p>
              we use Google Analytics and Adwords on our site to recognize a
              computer when a user accesses the site / track users while they
              browse the site / allow the use of a shopping cart on the site /
              improve the usability of the site / analyze the use of the site /
              administer the site / prevent fraud and improve site security /
              customize the site for each user / target ads that may be of
              particular interest to specific users;
            </p>

            <p>
              Most browsers allow you to refuse to accept cookies - for example:
            </p>

            <p>
              in Internet Explorer (version 10), you can block cookies using the
              available cookie handling cancellation settings by clicking on
              "Tools", "Internet Options" "Privacy" and "Advanced";
            </p>

            <p>
              in Firefox (version 24), you can block all cookies by clicking
              "Tools", "Options", "Privacy", selecting "Use custom settings for
              history" from the drop-down menu and deselecting "Accept cookies
              from websites"; and
            </p>

            <p>
              in Chrome (version 29), you can block all cookies by going to the
              "Customize and control" menu and clicking "Settings", "Advanced"
              and "Site settings" and then selecting "Block third-party cookies"
              in “Cookies and site data” section.
            </p>

            <p>
              Blocking all cookies will have a negative impact on the usability
              of many websites. If you block cookies, you will not be able to
              use all the features on our website.
            </p>

            <p>
              You can delete cookies that are already stored on your computer -
              for example:
            </p>

            <p>
              in Internet Explorer (version 10), you must delete the cookie
              files manually (see instructions for doing so at
              http://support.microsoft.com/kb/278835);
            </p>

            <p>
              in Firefox (version 24), you can delete cookies by clicking
              "Tools", "Options", "Privacy", selecting "Use custom settings for
              history", clicking "Show cookies" and then "Remove all cookies ”;
              and
            </p>

            <p>
              in Chrome (version 29), you can delete all cookies by accessing
              the “Customize and control” menu and clicking “Settings”,
              “Advanced”, “Clear browsing data” and then selecting “Delete
              cookies and other data of sites and plugins ”before clicking on“
              Clear browsing data ”.
            </p>

            <p>
              Deleting cookies will have a negative impact on the usability of
              many websites.
            </p>

            <p>
              The websites https://nizam.app/ do not assume any responsibility
              and advise you to consult legal experts if you are implementing
              the above model on your website.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PoliticaDePrivacidade
